// @flow

import React from 'react';
import {Block} from 'jsxstyle';

import {BLUE} from '@styles/colors';

import type {Element} from 'react';

type Props = {
  headlineType?: string,
  color?: string,
  margin?: string,
  textTransform?: string,
  textAlign?: string,
  children?: any,
  fontSize?: string,
  smMargin?: string,
  sentenceCase?: boolean,
  lowercase?: boolean,
  children: string,
};

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

export default ({
  headlineType,
  color,
  textAlign,
  textTransform,
  margin,
  children,
  fontSize,
  smMargin,
  sentenceCase,
  lowercase,
  ...rest
}: Props): Element<*> => (
  <Block
    component={headlineType ? headlineType : 'h2'}
    color={color ? color : BLUE}
    textTransform={
      sentenceCase || lowercase
        ? 'none'
        : textTransform
        ? textTransform
        : 'capitalize'
    }
    margin={margin ? margin : '0 0 40px 0'}
    fontSize={fontSize ? fontSize : '2.5rem'}
    lineHeight="3rem"
    textAlign={textAlign ? textAlign : 'center'}
    mediaQueries={mediaQueries}
    smMargin={smMargin ? smMargin : '10px 0'}
    {...rest}
  >
    {sentenceCase
      ? children.toLowerCase().replace(/^\w/, c => c.toUpperCase())
      : children}
  </Block>
);
