// @flow

import React, {Fragment, type Element} from 'react';
import {Col} from 'jsxstyle';
import {useSpring, animated} from 'react-spring';
import useHover from '../libs/use-hover';

import Link from '@components/Link';
import {ORANGE, WHITE, BLUE, TRANSPARENT} from '@styles/colors';

export type Props = {
  children?: Element<*> | Element<*>[] | string,
  buttonText?: string,
  buttonLink?: string,
  buttonColor?: string,
  buttonBorder?: string,
  buttonHeight?: string,
  buttonWidth?: string,
  fontSize?: string,
  copyColor?: string,
  onClickMethod?: () => void,
  margin?: string,
  mediaQueries?: Object,
  smWidth?: string,
  smHeight?: string,
  smMargin?: string,
  disabled?: Boolean,
};

const mq = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

const AnimatedButton = animated(Col);

export default function Button({
  buttonLink,
  buttonText,
  buttonColor,
  buttonBorder,
  buttonHeight,
  buttonWidth,
  ariaLabel,
  fontSize,
  copyColor,
  onClickMethod,
  margin,
  mediaQueries,
  smWidth,
  smHeight,
  smMargin,
  disabled,
  children,
}: Props) {
  const [isHovering, hoverProps] = useHover({
    mouseEnterDelayMS: 0,
    mouseLeaveDelayMS: 0,
  });
  const {colorRange, color} = useSpring({
    colorRange: isHovering
      ? [
          buttonColor,
          0,
          buttonColor === BLUE
            ? ORANGE
            : buttonColor === ORANGE
            ? BLUE
            : buttonColor === TRANSPARENT
            ? WHITE
            : buttonColor,
        ]
      : [buttonColor, 100, buttonColor],
    color:
      buttonColor === TRANSPARENT && isHovering
        ? BLUE
        : copyColor
        ? copyColor
        : WHITE,
    reverse: true,
  });

  return (
    <Fragment>
      {buttonLink && buttonLink !== null ? (
         <Link
         href={buttonLink}
         ariaLabel={ariaLabel}
         style={{textDecoration: 'none',width:'100%'}}         
       >
        <button
          className='button-transition'
          props={{onClick: onClickMethod, ...hoverProps}}
          style={{
            fontSize,
            textTransform: 'capitalize',
            height: buttonHeight ? buttonHeight : '50px',
            border: buttonBorder,
            width: buttonWidth ? buttonWidth : '100%',
            margin,
          }}
          >         
            {children ? children : buttonText}
          </button>
        </Link>
      ) : (
        <button className="button-transition" type="submit" disabled={disabled}>
          {children ? children : buttonText}
        </button>
      )}
    </Fragment>
  );
}
