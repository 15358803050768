// @flow

import React from 'react';
import {Block} from 'jsxstyle';

import {BLUE} from '@styles/colors';

import type {Element} from 'react';

type Props = {
  color?: string,
  children?: any,
  margin?: string,
  maxWidth?: string,
  textAlign?: string,
  smMargin?: string,
};

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

export default ({
  color,
  children,
  margin,
  maxWidth,
  textAlign,
  smMargin,
  ...rest
}: Props): Element<*> => (
  <Block
    component="p"
    color={color ? color : BLUE}
    margin={margin ? margin : '0'}
    lineHeight="1.5rem"
    maxWidth={maxWidth}
    textAlign={textAlign ? textAlign : 'center'}
    mediaQueries={mediaQueries}
    smMargin={smMargin ? smMargin : '0'}
    {...rest}
  >
    {children}
  </Block>
);
