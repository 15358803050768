import React, {Component, Fragment} from 'react';
import TransitionLink, {TransitionPortal} from 'gatsby-plugin-transition-link';
import {TimelineMax, Power1} from 'gsap';

import {WHITE} from '@styles/colors';

type Props = {
  children: any,
  href: string,
  style?: Object,
  activeStyle?: Object,
  ariaLabel?: string,
  onClick?: () => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
};

export default class InternalLink extends Component<Props> {
  cover: React.Ref = React.createRef();

  horizontal = ({node, props: {length: seconds}, direction}) => {
    const directionTo = direction === 'left' ? '-100%' : '100%';
    const directionFrom = direction === 'left' ? '100%' : '-100%';
    const wait = seconds / 6;
    const half = (seconds - wait) / 2;

    return new TimelineMax()
      .set(this.cover, {y: 0, x: directionFrom, display: 'block'})
      .to(this.cover, half, {
        x: '0%',
        ease: Power1.easeInOut,
      })
      .set(node, {opacity: 0})
      .to(
        this.cover,
        half,
        {
          x: directionTo,
          ease: Power1.easeInOut,
        },
        `+=${wait}`,
      );
  };

  moveInDirection = ({props, direction, node}) =>
    this.horizontal({props, direction, node});

  render() {
    const direction = 'left';
    const length = 1;
    const {href, style, activeStyle, ariaLabel, ...props} = this.props;
    return (
      <Fragment>
        <TransitionLink
          exit={{
            length,
            trigger: ({exit, node}) =>
              this.moveInDirection({props: exit, node, direction}),
          }}
          entry={{
            delay: length / 2,
          }}
          to={href}
          style={{...style}}
          activeStyle={{...activeStyle}}
          aria-label={ariaLabel}
          {...props}
        >
          {this.props.children}
        </TransitionLink>
        <TransitionPortal>
          <div
            ref={n => (this.cover = n)}
            style={{
              position: 'fixed',
              background: WHITE,
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              transform: 'translate3d(0, 100%, 0)',
            }}
          />
        </TransitionPortal>
      </Fragment>
    );
  }
}
