import React from 'react';

import ExternalLink from './ExternalLink';
import InternalLink from './InternalLink';

type Props = {
  children: any,
  href: string,
  style?: Object,
  activeStyle?: Object,
  onClick?: () => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
};

export default ({href, children, ...props}: Props) => {
  // This assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(href);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <InternalLink href={href} {...props}>
        {children}
      </InternalLink>
    );
  }
  return (
    <ExternalLink href={href} {...props}>
      {children}
    </ExternalLink>
  );
};
