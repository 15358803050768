import React from 'react';

type Props = {
  children: any,
  href: string,
  style?: Object,
  onClick?: () => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
  ariaLabel?: string,
};

export default ({href, ...props}: Props) => {
  const {
    children,
    style,
    onClick,
    onMouseEnter,
    onMouseLeave,
    ariaLabel,
  } = props;
  return (
    <a
      href={href}
      target={
        href.startsWith('tel') || href.startsWith('mailto') ? '_self' : '_blank'
      }
      rel="noopener noreferrer"
      aria-label={ariaLabel}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{...style}}
    >
      {children}
    </a>
  );
};
