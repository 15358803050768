// @flow

export const ORANGE: string = '#ff7c1c';
export const BLUE: string = '#004363';
export const WHITE: string = '#ffffff';
export const BLACK: string = '#000000';
export const GREY: string = '#6C6D6F';
export const LIGHT_GREY: string = '#F6F6F6';
export const MID_GREY = '#D8D8D8';
export const DARKER_GREY: string = '#E9E8E8';
export const TRANSPARENT: string = 'rgba(0, 0, 0, 0)';
